/* eslint-disable max-lines */
import { ArticleResolution, ArticleSortType } from '@wix/answers-api';
import { PageType, VoteType } from './types';

export const PAGES = {
  ARTICLE: PageType.Article,
  CATEGORY: PageType.Category,
  SEARCH_RESULTS: PageType.SearchResults,
  HOMEPAGE: PageType.Homepage,
  TICKET: PageType.Ticket,
  HOME: PageType.Homepage,
  ROADMAP: PageType.RoadMap,
  PROFILE: PageType.Profile,
  CONTACT: PageType.Contact,
  KNOWN_ISSUES: PageType.KnownIssues,
};

export const ROADMAP_RESOLUTIONS = [
  ArticleResolution.COLLECTING_VOTES,
  ArticleResolution.PLANNED,
  ArticleResolution.IN_DEVELOPMENT,
  ArticleResolution.COMING_SOON,
  ArticleResolution.RELEASED,
];

export const LOCALES = {
  DE: 'de',
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  HE: 'he',
  IT: 'it',
  JA: 'ja',
  KO: 'ko',
  NL: 'nl',
  PT: 'pt',
  RU: 'ru',
  TR: 'tr',
  UK: 'uk',
};

export const WIX_STUDIO_SUPPORTED_LANGUAGES = [
  LOCALES.EN,
  LOCALES.DE,
  LOCALES.ES,
  LOCALES.FR,
  LOCALES.JA,
  LOCALES.PT,
  LOCALES.HE,
  LOCALES.IT,
  LOCALES.KO,
  LOCALES.NL,
  LOCALES.TR,
  LOCALES.RU,
];

export const HEADER_FOOTER_SUPPORT_LANGUAGES = [
  'en',
  'de',
  'es',
  'fr',
  'it',
  'nl',
  'pt',
  'ru',
  'ja',
  'ko',
  'tr',
  'hi',
  'cs',
  'th',
  'zh',
  'uk',
  'vi',
  'id',
];

export const MOCKED_ROADMAP_LABELS = [
  {
    id: '5e2c392b-8762-4d09-8a0d-121de62d37d8',
    name: 'Roadmap: Wix Editor',
  },
  {
    id: 'ca8c47bc-ff69-4006-a0b7-b524906ecabe',
    name: 'Roadmap: Wix Bookings',
  },
  {
    id: 'c9a46fd8-6919-4969-a26c-dc03f5c60e25',
    name: 'Roadmap: Wix Blog',
  },
  { id: '18d84448-9998-4590-a9d8-098a8678b9a4', name: 'Roadmap: Velo' },
  {
    id: '9af919a1-8b25-4ce9-a4a5-d807519011ae',
    name: 'Roadmap: Wix Stores',
  },
];

export const EDITORS_NAMES = ['ADI', 'Wix Editor', 'Editor X'];

export const URL_MSID_PATTERN =
  /[\da-f]{8}-[\da-f]{4}-[0-5][\da-f]{3}-[089ab][\da-f]{3}-[\da-f]{12}/;

export const BI = {
  ORIGINS: {
    ROADMAP: 'hc roadmap',
  },
  SOURCE_NAMES: {
    ARTICLE: 'article',
    SEARCH_RESULTS: 'search_results',
    CATEGORY: 'category',
    TICKET: 'ticket',
    HOME: 'home',
    PROFILE: 'user_profile',
    ROADMAP: 'roadmap',
    CONTACT: 'wix_is_down_contact_form',
    KNOWN_ISSUES: 'ki page',
  },
  SOURCE_COMPONENT_NAME: {
    WISHLIST_FORM: 'wishlist_form',
  },
  ITEM_TYPES: {
    KNOWN_ISSUE: 'known_issue',
    FEATURE_REQUEST: 'feature_request',
    ARTICLE: 'article',
    TICKET: 'ticket',
    PROFILE: 'user_profile',
    INCIDENT_BANNER: 'incident_banner',
    CONTACT: 'wix_is_down_contact_form',
    VIDEO: 'video',
    FEATURE_REQUEST_ENROLLMENT: 'feature_request_enrollment',
    WIX_BLOG: 'wix_blog',
    WIX_LEARN: 'wix_learn',
    WIX_SEO_LEARN: 'wix_seo_learn',
    WIX_VELO: 'wix_velo',
    SEARCH_RESULTS: 'search_results',
    SUMMARIZED_ANSWER_HYPERLINK: 'summarized_answer_hyperlink',
    SUMMARIZED_AMSWER_BASED_ARTICLE: 'summarized_answer_based_article',
    SUMMARIZED_ANSWER_HELPFUL: 'summarized_answer_helpful',
    SUMMARIZED_ANSWER_NOT_HELPFUL: 'summarized_answer_not_helpful',
  },
  ITEM_NAMES: {
    HELPFUL_VOTE_OPTION: 'helpful vote option',
    HIRE_A_PARTNER: 'hire a partner',
    YOUTUBE_VIDEO: 'video',
    VOTE: {
      FEATURE_REQUEST_VOTE: 'feature request vote option',
      FEATURE_REQUEST_FOLLOW: 'feature request follow option',
      KNOWN_ISSUE_VOTE: 'known issue vote option',
    },
    TRENDING_ARTICLES: 'trending articles',
    TOPICS_SECTION: 'categories section',
    PRODUCT_ROADMAP: 'product roadmap cta',
    EXPLORE_MORE_RESOURCES_SECTION: 'more resources section',
    STUDIO_COMMUNITY_FORUM: 'studio community cta',
    COMMUNITY_WISHLIST: 'studio community wishlist cta',
    BLOG: 'wix blog',
    MORE_SEARCH_RESULTS: 'search results list',
    RELATED_CONTENT: 'related content',
    STUDIO_DISCORD: 'studio community wishlist cta '
  },
  EXPLORE_MORE: 'Explore More',
  CLICKED_ITEM_TYPES: {
    SECTION_EXPAND: 'section_expand',
    SECTION_COLLAPSE: 'section_collapse',
    CATEGORY_SELECTION: 'category_selection',
    SEE_ALL: 'see_all',
    SUB_CATEGORY_SELECTION: 'sub_category_selection',
    ARTICLE_SELECTION: 'article_selection',
    FEATURE_REQUEST_SELECTION: 'feature_request_selection',
    KNOWN_ISSUE_SELECTION: 'known_issue_selection',
    HOME_PAGE: 'home_page',
    HOME_PAGE_EXPAND: 'homepage_expand',
    INTERNAL_LINK: 'internal_link',
    EXTERNAL_LINK: 'external_link',
    EXTERNAL_FORM_LINK: 'form_opened_via_external_link',
    ARTICLE_LINK: 'article_link',
    ARTICLE_CLICK: 'article_click',
    CTA: 'CTA',
    RELATED_CONTENT: 'related_content',
    PICTURE: 'picture',
    ALL_TOPICS: 'all_topics',
    PAGE_ACTION: 'page_action',
    TRENDING_TOPICS: 'trending_topics',
    TRENDING_TOPICS_CATEGORY: 'trending_topics_category',
    TRENDING_TOPICS_ARTICLE: 'trending_topics_article',
    HIRE_A_WIX_PARTNER: 'hire_a_wix_partner',
    TAB_SELECTION: 'tab_selection',
    BREADCRUMBS: 'breadcrumbs',
    NEXT_BUTTON: 'next',
    WALKTHROUGH_START_TOUR: 'start tour',
    SKIP: 'skip',
    RESIZE: {
      INCREASE: 'resize_increase',
      DECREASE: 'resize_decrease',
    },
    MORE_RESOURCES: 'more_resources',
    WIX_STUDIO_COMMUNITY_FORUM: 'wix studio community forum',
    WIX_STUDIO_DISCORD: 'wix studio discord',
    CLOSE: 'close',
    CANCEL: 'cancel',
    SEND: 'send',
    BACK: 'back',
    VOTE: 'vote',
    FOLLOW: 'follow',
    PAGE_SELECTION: 'page_selection',
    TAG_SELECTION: 'tag_selection',
    SOURCE_HOMEPAGE_SELECTION: 'source_homepage_selection',
    WISHLIST: {
      SEND: 'send',
      SEND_WITH_SUGGESTED: 'send with alternative suggested',
      SEND_AFTER_SUGGESTED: 'send my feature',
    },
    VIDEO: 'video click',
    BLOG_POST: 'blog post',
    BLOG_POST_AUTHOR: 'blog post author',
    KNOWN_ISSUES_SELECTION: 'known_issues_selection',
    PRODUCT_FILTER: 'product_filter',
    SIDEBAR_ANCHOR: 'sidebar anchor',
    INTERNAL_ANCHOR: 'internal anchor',
    OPT_IN: 'opt_in',
    TRY_AGAIN: 'try_again',
    OK: 'ok',
    LINK: 'link',
    REQUEST_A_FEATURE: 'request_a_feature',
    REQUEST_A_FEATURE_DIRECT_TO_DESKTOP: 'request_a_feature_direct_to_desktop',
    ALTERNATIVE_FEATURE_REQUEST_SELECTED:
      'alternative_feature_request_selected',
    SHOW_MORE: 'show more',
    SEND_WITH_ALTERNATIVES: 'send with alternative suggested',
  },
  SELECTORS: {
    COLLAPSIBLE_COMPONENTS: '.collapsible-heading',
    CLICKABLE_IMAGES: 'img[class="clickable"]',
    CUSTOM_HTML_ARTICLE_CTA_BUTTON: 'action-box-action-btn',
    CUSTOM_HTML_ARTICLE_LOG_IN_BUTTON: 'article-log-in-button',
    CUSTOM_HTML_ARTICLE_GET_STARTED_BUTTON: 'article-get-started-button',
    PAGE: '#__next',
    HEADER: 'header',
    TABS: '.tab-heading',
  },
  SEARCH_RESULT_SELECTION: {
    SEARCH_BAR_DROPDOWN_LIST: 'search_bar_dropdown_list',
    FULL_SEARCH_RESULTS_LIST: 'full_search_results_list',
  },
  USER_ENGAGEMENT_PINGER_INTERVAL:
    process.env.NODE_ENV === 'test' ? 1000 : 10000,
  FEEDBACK_DETAILS: {
    OLD: 'old_feedback_form',
    NEW: 'new_feedback_form',
  },
  HIRE_A_PARTNER: {
    OLD: 'a_hire_a_partner',
    NEW: 'b_hire_a_partner',
  },
  BANNERS_TYPE: {
    STATUS_PAGE: 'status_page',
    HELP_CENTER: 'help_center',
  },
  WISHLIST_FORM: {
    MAIN: 'main',
    ALTERNATIVE_SUGGESTER: 'alternative_suggester',
  },
  PERSONALIZED_CONTENT_SECTIONS: {
    TRENDING_ARTICLES: 'Trending Articles',
    RELATED_CONTENT: 'Related Content',
  },
  VOTE_TYPE: {
    FEATURE_REQUEST_VOTE: 'feature_request_vote',
    FEATURE_REQUEST_FOLLOW: 'feature_request_follow',
    FEATURE_REQUEST_ENROLLMENT: 'feature_request_enrollment',
  },
  MODAL_NAME: {
    FEATURE_REQUEST_FAILED_LIMIT: 'request_a_feature_failed_request_limit',
    ROADMAP_WALKTHROUGH: 'roadmap_walkthrough',
    FEATURE_REQUEST_ENROLLMENT_CONFIRMATION:
      'feature_request_enrollment_confirmation',
    FEATURE_REQUEST_ENROLLMENT_FAILED: 'feature_request_enrollment_failed',
    FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED:
      'feature_request_enrollment_opt_out_failed',
    REQUEST_A_FEATURE: 'request a feature',
  },
  ROADMAP_WALKTHROUGH_SCREEN_NAME: {
    WELCOME: 'welcome',
  },
  ACTION_TYPE: {
    MODAL_LOAD: 'modal_load',
    MODAL_CLICK: 'modal_click',
  },
  SCREEN_NAME: {
    FEATURE_REQUEST_ENROLLMENT_CONFIRMATION:
      'feature_request_enrollment_confirmation',
    FEATURE_REQUEST_ENROLLMENT_FAILED: 'feature_request_enrollment_failed',
    FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED:
      'feature_request_enrollment_opt_out_failed',
    REQUEST_A_NEW_FEATURE: 'request_a_new_feature',
    FEATURE_ALREADY_EXISTS: 'feature_already_exists',
    ALTERNATIVE_FEATURE_REQUEST: 'alternative_feature_request',
    LOADER: 'loader',
    ERROR_TOO_MANY_REQUESTS: 'request_a_feature_failed_request_limit',
  },
  BANNER_TYPE: {
    NEW_FEATURE_ANNOUNCEMENT: 'new_feature_announcement',
  },
  BANNER_NAME: {
    NEW_FEATURE_FEATURE_REQUEST_ENROLLMENT:
      'new_feature_feature_request_enrollment',
    ARTICLE_IN_AB_TEST: 'Article in AB test',
  },
};

export const voteTypeToBiVoteType = {
  [VoteType.FOLLOW]: BI.VOTE_TYPE.FEATURE_REQUEST_FOLLOW,
  [VoteType.VOTE]: BI.VOTE_TYPE.FEATURE_REQUEST_VOTE,
  [VoteType.ENROLLMENT]: BI.VOTE_TYPE.FEATURE_REQUEST_ENROLLMENT,
};

export const PRODUCTION_DOMAIN = 'support.wix.com';
export const ANSWERS_TENANT_ID = '12345678-1234-1234-1234-1234567890ab';

export const WIX_STUDIO_COMMUNITY_FORUM_LINK =
  'https://forum.wixstudio.com/c/ask-the-community/8?utm_source=cc_help_center';

export const WIX_STUDIO_DISCORD_LINK = 'https://discord.com/invite/wixstudio';

export const CHATBOT_URL =
  'https://www.wix.com/support-chatbot?t=2&alert=0&nodeId=RNc3EQNG7DHSFMjbPaLn&referral=';

export const WIX_CONTACT_URL =
  'https://www.wix.com/contact?&referral=answers_submit_ticket';

export const ANSWERS_API_BASE_URL = `https://${PRODUCTION_DOMAIN}/api/v1`;

export const API_CLIENT_ID = '57999a1b-940f-4227-adca-c554628ea935';

export const EXPERIMENTS = {
  ID: '__EXPERIMENTS__',
  SCOPES: ['customer-care'],
  SPECS: {
    REDIRECT_FROM_HELP_CENTER_ON_NOT_HELPED:
      'specs.engage.RedirectFromHelpCenterOnNotHelped',
    REDIRECT_FROM_HELP_CENTER_ONLY_BILLING_PREMIUM:
      'specs.engage.RedirectFromHelpCenterOnlyBillingPremium',
    BACKUP_CONTACT_PAGE: 'specs.backupContactPage',
    BACKUP_CONTACT_PAGE_TEST: 'specs.backupContactPageTest',
    EDITOR_X_NOTIFICATION: 'specs.EditorXNotification',
    NEW_SEARCH_RESULTS_PAGE_ALL_LOCALES: 'specs.NewSearchResultsPageAllLocales',
    PERSONALIZED_TRENDING_ARTICLES: 'specs.PersonalizedTrendingArticles',
    PERSONALIZED_RELATED_ARTICLES: 'specs.PersonalizedRelatedArticles',
    AUTO_ENROLLMENT: 'specs.AutoEnrollment',
    ARTICLE_FEEDBACK_FEB24_VERSION: 'specs.ArticleFeedbackFeb24Version',
    NEW_SUBHEADER_DESIGN: 'specs.NewSubheaderDesign',
    MARIA_SHEVCHENKO_TEST: 'specs.MariaShevchenkoTest',
    KNOWLEDGE_AGENT_SEARCH_MOBILE: 'specs.KnowledgeAgentSearchMobile',
    UNIFIED_SESSION: 'specs.UnifiedSession',
    KNOWLEDGE_AGENT_SEARCH_NON_ENGLISH: 'specs.KnowledgeAgentSearchNonEnglish',
    KA_SEARCH: 'specs.KASearch',
    IMPROVE_SIMILARITY_MECHANISM: 'specs.ImproveSimilarityMechanism',
    WISHLIST_REQUEST_NOTIFICATIONS: 'specs.WishlistRequestNotifications',
    NEXT_GEN_SEARCH: 'specs.GeneratedAnswerResultHC',
    ARTICLE_PAGE_UX_CHANGES: 'specs.ArticlePageUXChanges'
  },
};

export const EMPTY_CATEGORY_ID = '00000000-0000-0000-0000-000000000000';

export const ARTICLES_PAGE_SIZE = 350;

export const SEARCH_ARTICLES_PAGE_SIZE = 20;

export const CATEGORY_ARTICLES_PAGE_SIZE = 20;

export const BILLING_AND_PREMIUM_CATEGORIES = [
  'f78196b2-7b20-45d1-9ec2-3fb4504c6c38',
  '11af63e5-e36f-45c7-8596-031ac665e06d',
  '04e9f910-d5f6-44ea-b702-1646844dd106',
  'f2bb50e4-d721-4709-b94e-29b04d0d2451',
  'd3a9282e-b06f-4dc3-a973-1d36477461d7',
  '967ff141-bc5d-4a18-b664-10f7495da15e',
  '75c5cb39-36b5-4fe0-a7d2-2f4c1fae0ab3',
  'ce454447-8a62-4431-9b84-1ec3e3a1f46b',
  '2ec24e03-f797-4a78-939a-f1a02c9eb329',
  '35473554-6809-447f-916a-e38527ebcd39',
];

export const WIX_STATUS_DOMAIN = 'https://status.wix.com';

export const WIX_STATUS_PATH = '/api/v2/incidents.json';

export const SEO = {
  PLACEHOLDERS: {
    CATEGORY_TITLE: '$CATEGORY_TITLE',
    COMPANY_NAME: '$COMPANY_NAME',
    ARTICLE_TITLE: '$ARTICLE_TITLE',
    ARTICLE_PREVIEW: '$ARTICLE_PREVIEW',
  },
  COMPANY_NAME: 'Wix Support',
};

export const PAGE_TYPE_SEO_RPC = {
  ARTICLE: 'ARTICLE',
  CATEGORY: 'CATEGORY',
  CONTACT_PAGE: 'CONTACT_PAGE',
  HOME_PAGE: 'HOME_PAGE',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
};

export const ONE_HOUR_MS = 3600000;
export const ONE_HOUR_SECONDS = ONE_HOUR_MS / 1000;

export const FEDOPS = {
  INTERACTION_NAMES: {
    SEARCH_ARTICLES: 'search_articles',
    VOTE_ARTICLE: 'vote_article',
    HELPFUL_ARTICLE: 'helpful_article',
    SUBMIT_TICKET: 'submit_ticket',
    ADDITIONAL_FEEDBACK: 'additional_feedback',
  },
};

export const SLED = {
  COOKIES: {
    PETRI_OVR: 'petri_ovr',
  },
  HEADERS: {
    DEPLOY_PREVIEW: 'x-wix-route-kb-client-nextjs',
  },
};

export const WIX_RECAPTCHA_KEY = '6Le_WGgdAAAAAPDQseS7wvNTDEYsdzlq1EOpyGQF';

export const SENTRY = {
  DSN: 'https://845a4a6ee7a84ecda9d0d38fdc945bf0@sentry-next.wixpress.com/2742',
};

export const REDIS = {
  GET_HELP_CENTER_DATA: { KEY: 'getHelpCenterData', TTL: 300 },
  GET_CATEGORY_BY_URI: { KEY: 'getCategoryByUri', TTL: 300 },
  GET_CATEGORY_TRANSLATIONS: { KEY: 'getCategoryTranslations', TTL: 300 },
  GET_PAGE_SEO: { KEY: 'getPageSeo', TTL: 300 },
  GET_TENANT_INFO: { KEY: 'getTenantInfo', TTL: 300 },
  GET_LABELS: { KEY: 'getLabels', TTL: 300 },
  GET_BLOG_POSTS: { KEY: 'getBlogPosts', TTL: 86400 },
  GET_ARTICLE_TYPE_COUNT: { KEY: 'getArticleTypeCount', TTL: 300 },
  SEARCH_ARTICLES: { KEY: 'searchArticles', TTL: 300 },
};

export const isInternalRoute = (url?: string): boolean =>
  !!url?.startsWith('/_next/data');

export const AGENT_TOOLBAR = {
  CLASSES: {
    OPEN: 'agent-toolbar-open',
    CLOSE: 'agent-toolbar-close',
    CONTAINER: 'user-toolbar-container',
  },
};

export const DEFAULT_SEARCH_METHOD = 'Answers';
export const KA_SEARCH_METHOD = 'Knowledge Agent';

export enum SearchResultsFilter {
  ALL = 'all',
  ARTICLES = 'articles',
  KNOWN_ISSUES = 'known-issues',
  ROADMAP = 'roadmap',
  FEATURE_REQUESTS = 'feature-requests',
}

export const COOKIES = {
  WIX_CLIENT_ID: '_wixCIDX',
  WIX_LANGUAGE: 'wixLanguage',
  WIX_CLIENT: 'wixClient',
  WIX_ANSWERS_CLIENT_ID: '_AC',
  WIX_UID: '_wixUIDX',
};

export const LOCAL_STORAGE_PREFIX = {
  FEEDBACK_VOTE: 'feedback-vote-',
  ADDITIONAL_FEEDBACK_OPTION: 'additional-feedback-',
};

export const HIRE_A_PARTNER_LOCALES = [LOCALES.EN, LOCALES.PT];

export const TRENDING_ARTICLES_NUM_ITEMS = 5;
export const TOPICS_SECTION_NUM_ITEMS = 9;

export const ALL_PRODUCTS_LABEL_ID = '0000000000000';

export const AUTO_VOTE = 'autoVote';

export const TOOLTIP_ENTER_DELAY = 300;

export const BI_PROFILE_USER_TRENDING_ARTICLES_FIELD = 'user_trending_articles';

export const KNOWN_ISSUE_CATEGORY_ID = '501eb51e-a873-4c4f-a98c-8005f3127e30';

export const getKnownIssuesSortByOptions = (hasSearchQuery?: boolean) =>
  (hasSearchQuery ? [ArticleSortType.TEXTUAL_SCORE] : []).concat([
    ArticleSortType.CREATION_DATE_DESC,
    ArticleSortType.LAST_PUBLISH_DATE_DESC,
  ]);

export const StorageKeys = {
  AUTO_ENROLLMENT_NOTIFICATION_DISMISSED: 'autoEnrollmentNotificationDismissed',
};

export const SEARCH_DEBOUNCE = 300;

export const KI_PUBLISH_DATE_FORMAT = 'LL';

export const MAX_PERSONALIZED_NAME_LENGTH = 15;

export const SESSION_STORAGE = {
  KEYS: {
    USER_SEARCHED: 'userSearched',
  },
};

export const MAX_SEARCH_LENGTH = 100;

export const EDITOR_X_LABEL_ID = '3008b109-b7ca-45cc-b80b-942a91fac828';

export const EXCLUDED_FR_LABELS = [EDITOR_X_LABEL_ID, ALL_PRODUCTS_LABEL_ID];

export const MOVE_TO_TOP_Z_INDEX = 99999;
