import React from 'react';
import {
  AnswersApi,
  Category,
  createAnswersApi,
  createApiTestKit,
} from '@wix/answers-api';
import { ANSWERS_API_BASE_URL } from '../constants';
import { createHttpService } from '../utils/answers';
import { PageTemplateData } from '../components/PageTemplate';
import { noop } from '@wix/answers-lib';
import { User } from '@wix/ambassador-identity-account-v2-account/build/cjs/types.impl';
import { PageType, SessionBI } from '../types';
import moment from 'moment';

export const answersApi = createAnswersApi({
  baseUrl: ANSWERS_API_BASE_URL,
  http: createHttpService(),
  clientName: 'helpcenter',
});

export type ContextType = {
  pageTemplateData: PageTemplateData;
  setPageTemplateData: (pageTemplateData: PageTemplateData) => void;
  answersApi: AnswersApi;
  categoriesTree: Category[];
  isMobile: boolean;
  isTablet: boolean;
  isLoggedInUser: boolean;
  isWixStudioUser: boolean;
  isPartner: boolean;
  isWixEmployee: boolean;
  experiments: { scopes: string[] };
  pageType: PageType;
  sessionBI: SessionBI;
  setSessionBI: (obj: SessionBI) => void;
  fetchSessionDebounceBI: (isLoggedInUser: boolean) => Promise<string | null>;
  user?: User;
  loggedInAccountId?: string | null;
  searchSessionId: string;
  setSearchSessionId: (newSearchSessionId: string) => void;
};

export const Context = React.createContext<ContextType>({
  answersApi,
  categoriesTree: [],
  pageTemplateData: {},
  setPageTemplateData: noop,
  isMobile: false,
  isTablet: false,
  isLoggedInUser: false,
  isWixStudioUser: false,
  isPartner: false,
  isWixEmployee: false,
  experiments: { scopes: [] },
  pageType: PageType.Homepage,
  sessionBI: {
    lastSessionDate: moment(),
    selfServiceSessionId: null,
  },
  setSessionBI: noop,
  fetchSessionDebounceBI: noop,
  user: undefined,
  loggedInAccountId: undefined,
  searchSessionId: '',
  setSearchSessionId: noop,
});

export const TestContextProvider = (props: {
  value: Partial<ContextType>;
  children: JSX.Element;
}) => (
  <Context.Provider
    value={{
      answersApi: createApiTestKit(),
      categoriesTree: [],
      pageTemplateData: {},
      setPageTemplateData: noop,
      isMobile: false,
      isTablet: false,
      isLoggedInUser: false,
      isWixStudioUser: false,
      experiments: { scopes: [] },
      isPartner: false,
      isWixEmployee: false,
      pageType: PageType.Homepage,
      sessionBI: {
        lastSessionDate: moment(),
        selfServiceSessionId: null,
      },
      setSessionBI: noop,
      fetchSessionDebounceBI: noop,
      user: undefined,
      searchSessionId: '',
      setSearchSessionId: noop,
      ...props.value,
    }}
  >
    {props.children}
  </Context.Provider>
);
