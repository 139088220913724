import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { usePathname } from 'next/navigation';
import { CustomModalLayout, Modal, Search, Heading } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { noop } from '@wix/answers-lib';
import { v4 as uuidv4 } from 'uuid';
import { RenderHTML } from '../../RenderHTML';
import { Context } from '../../../context';
import { SearchBarModalBody } from './SearchBarModalBody';
import { pushUrl } from '../../InnerLink';
import { DATA_HOOKS } from '../../../dataHooks';
import { EXPERIMENTS, SESSION_STORAGE } from '../../../constants';
import { PageType } from '../../../types';
import { useRouter } from 'next/router';
import css from './index.module.scss';
import { useExperiments } from '@wix/fe-essentials-standalone';
import classNames from 'classnames';

type SearchBarModalProps = {
  pageType: PageType;
};

export const SearchBarModal: FunctionComponent<SearchBarModalProps> = ({
  pageType,
}) => {
  const { locale } = useRouter();
  const pathname = usePathname();
  const [showSearchBarModal, setShowSearchBarModal] = useState(false);
  const [query, setQuery] = useState('');
  const { experiments } = useExperiments({ readOnly: true });
  const { setSearchSessionId } = useContext(Context);

  const isNewSubheaderDesignEnabled =
    experiments.enabled(EXPERIMENTS.SPECS.NEW_SUBHEADER_DESIGN);

  const {
    isLoggedInUser,
    pageTemplateData: { itemId, itemType },
    user,
    isMobile,
  } = useContext(Context);

  const { t } = useTranslation();

  const openModal = () => {
    if (!showSearchBarModal) {
      setShowSearchBarModal(true);
    }
  };

  const closeModal = () => {
    setShowSearchBarModal(false);
    setQuery('');
  };

  const goToSearchResultsPage = async (overrideQuery?: string) => {
    closeModal();
    sessionStorage.setItem(
      SESSION_STORAGE.KEYS.USER_SEARCHED,
      JSON.stringify({
        itemId,
        itemType,
        pageType,
        inputType:
          overrideQuery && overrideQuery?.length > 0
            ? 'suggested question'
            : '',
      })
    );
    setSearchSessionId(uuidv4());
    await pushUrl(
      `/${locale}/search?term=${overrideQuery && overrideQuery?.length > 0 ? overrideQuery : encodeURIComponent(query)
      }`
    );
  };

  useEffect(() => {
    setShowSearchBarModal(false);
  }, [pathname]);

  return (
    <div className={classNames(css.searchBarModal, {
      [css.newSubheaderDesign]: isNewSubheaderDesignEnabled,
    })} onClick={openModal}>
      <Search
        className={css.searchBarModalCTA}
        placeholder={t('subheader-searchbar.placeholder')}
        onChange={noop}
        value={''}
        dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_ENTRY_POINT}
        disableEditing={true}
        size={isMobile || isNewSubheaderDesignEnabled ? 'large' : 'medium'}
      />
      <Modal
        dataHook={DATA_HOOKS.SEARCH_BAR_MODAL}
        isOpen={showSearchBarModal}
        onRequestClose={closeModal}
        scrollable={false}
        screen={isMobile ? 'mobile' : 'desktop'}
      >
        <CustomModalLayout
          dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_BODY}
          onCloseButtonClick={closeModal}
          showHeaderDivider={!isMobile}
          width={572}
          overflowY="none"
          className={css.searchBarModalLayout}
          title={
            <Heading
              size={isMobile ? 'large' : 'medium'}
              className={css.searchBarModalTitle}
            >
              <RenderHTML
                html={
                  isLoggedInUser
                    ? t('search-bar-modal.title-with-user', {
                      firstName: user?.firstName,
                    })
                    : t('search-bar-modal.title-without-user')
                }
              />
            </Heading>
          }
          content={
            <SearchBarModalBody
              goToSearchResultsPage={goToSearchResultsPage}
              query={query}
              setQuery={setQuery}
            />
          }
        />
      </Modal>
    </div>
  );
};
